var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-l": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v("\n          Work Orders\n          "),
                      _c("v-spacer"),
                      _vm.showChangeFacilityButton
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ma-0",
                              attrs: { icon: "", color: "error" },
                              on: { click: _vm.changeFacility }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-power")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "pr-5",
                            attrs: {
                              label: "Search or Scan Work Order",
                              "append-outer-icon": "mdi-send",
                              clearable: true
                            },
                            on: {
                              change: _vm.filter,
                              "click:clear": _vm.clearWorkOrderSearch,
                              "click:append-outer": _vm.filter
                            },
                            model: {
                              value: _vm.workOrderSelection,
                              callback: function($$v) {
                                _vm.workOrderSelection = $$v
                              },
                              expression: "workOrderSelection"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              items: _vm.lineSelectList,
                              label: "Line",
                              clearable: ""
                            },
                            on: { change: _vm.filter },
                            model: {
                              value: _vm.lineSelection,
                              callback: function($$v) {
                                _vm.lineSelection = $$v
                              },
                              expression: "lineSelection"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.workOrderModel.headers,
                      items: _vm.workOrderFilterModel,
                      "rows-per-page-items": [
                        10,
                        50,
                        { text: "All", value: -1 }
                      ],
                      loading: _vm.isLoading()
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "no-data",
                        fn: function() {
                          return [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  value: true,
                                  color: "error",
                                  icon: "warning"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Sorry, nothing to display here\n            "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "progress",
                        fn: function() {
                          return [
                            _c("v-progress-linear", {
                              attrs: {
                                color: "blue",
                                indeterminate: true,
                                height: "2"
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(props.item.workOrderNumber) +
                                  "\n            "
                              )
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(props.item.productionLine))
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(props.item.productDescription))
                            ]),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { link: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.createPrintJob(props.item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Create Print Job\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("PrintJobsModal", {
            attrs: { workOrder: _vm.workOrder },
            on: { "save-changes": _vm.printJobSaved },
            model: {
              value: _vm.showPrintJobsModal,
              callback: function($$v) {
                _vm.showPrintJobsModal = $$v
              },
              expression: "showPrintJobsModal"
            }
          }),
          _c("LocationModal", {
            on: { "save-location-changes": _vm.updateLocation },
            model: {
              value: _vm.showHomeScheduleModal,
              callback: function($$v) {
                _vm.showHomeScheduleModal = $$v
              },
              expression: "showHomeScheduleModal"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }