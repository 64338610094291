var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1400", persistent: "" },
      on: { "save-click": _vm.printJobSaved },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _vm.value
        ? _c(
            "v-card",
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-card-title", { staticClass: "px-4" }, [
                            _c("span", { staticClass: "headline" }, [
                              _vm._v("Print Job")
                            ])
                          ]),
                          _c(
                            "v-form",
                            { ref: "form" },
                            [
                              _c(
                                "v-container",
                                { staticClass: "px-4", attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Work Order Number",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder.workOrderNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "workOrderNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.workOrderNumber"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Production Line",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder.productionLine,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "productionLine",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.productionLine"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Material Type",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.materialType,
                                              callback: function($$v) {
                                                _vm.materialType = $$v
                                              },
                                              expression: "materialType"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Category Type",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.workOrder.categoryType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "categoryType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.categoryType"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Component Size",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.workOrder.size,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "size",
                                                  $$v
                                                )
                                              },
                                              expression: "workOrder.size"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Lot Code",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.lotCodeString,
                                              callback: function($$v) {
                                                _vm.lotCodeString = $$v
                                              },
                                              expression: "lotCodeString"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md12: "", sm12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Product Description",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .productDescription,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "productDescription",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.productDescription"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Print Line",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.jobPrintLine,
                                              callback: function($$v) {
                                                _vm.jobPrintLine = $$v
                                              },
                                              expression: "jobPrintLine"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-content-click": false,
                                                "nudge-right": 40,
                                                lazy: "",
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "full-width": "",
                                                "min-width": "290px"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                hint:
                                                                  "MM/DD/YYYY",
                                                                label:
                                                                  "Production Date",
                                                                "prepend-icon":
                                                                  "event",
                                                                readonly: "",
                                                                required: ""
                                                              },
                                                              on: {
                                                                blur:
                                                                  _vm.onChangeDate
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.dateFormatted,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.dateFormatted = $$v
                                                                },
                                                                expression:
                                                                  "dateFormatted"
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                571248507
                                              ),
                                              model: {
                                                value: _vm.menu2,
                                                callback: function($$v) {
                                                  _vm.menu2 = $$v
                                                },
                                                expression: "menu2"
                                              }
                                            },
                                            [
                                              _c("v-date-picker", {
                                                on: {
                                                  input: function($event) {
                                                    _vm.menu2 = false
                                                  }
                                                },
                                                model: {
                                                  value: _vm.date,
                                                  callback: function($$v) {
                                                    _vm.date = $$v
                                                  },
                                                  expression: "date"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              rules: _vm.isRequiredRules,
                                              label: "Current Shift",
                                              "validate-on-blur": true,
                                              items: _vm.shifts
                                            },
                                            model: {
                                              value: _vm.shift,
                                              callback: function($$v) {
                                                _vm.shift = $$v
                                              },
                                              expression: "shift"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "", xs6: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              rules: _vm.isRequiredRules,
                                              label: "Resin Code",
                                              items: _vm.resinCodeList,
                                              "item-text": "name",
                                              "item-value": "id",
                                              "validate-on-blur": true
                                            },
                                            model: {
                                              value: _vm.resinCode,
                                              callback: function($$v) {
                                                _vm.resinCode = $$v
                                              },
                                              expression: "resinCode"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { md12: "", sm12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Barcode",
                                              disabled: "",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.generatedBarCode,
                                              callback: function($$v) {
                                                _vm.generatedBarCode = $$v
                                              },
                                              expression: "generatedBarCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "grey", flat: "" },
                              on: { click: _vm.hideDialog }
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                flat: "",
                                loading: _vm.isSaving,
                                flat: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }